// extracted by mini-css-extract-plugin
export var breadcrumb = "styles-module--breadcrumb--M-vSB";
export var breakme = "styles-module--breakme--yLyTL";
export var content = "styles-module--content--Tc5yP";
export var header = "styles-module--header--QzAgr";
export var holder = "styles-module--holder--Y0sOU";
export var howHolder = "styles-module--howHolder--zbFSf";
export var image = "styles-module--image--bp9EY";
export var kaynakca = "styles-module--kaynakca--tHEKT";
export var kidsHolder = "styles-module--kidsHolder--gZ9rT";
export var listHolder = "styles-module--listHolder---RjCK";
export var productHolder = "styles-module--productHolder--yCzbY";
export var rightCoughHolder = "styles-module--rightCoughHolder--g8kkU";
export var typesHolder = "styles-module--typesHolder--aCNog";
export var whatHolder = "styles-module--whatHolder--XOOBy";
export var whyHolder = "styles-module--whyHolder---hAK6";