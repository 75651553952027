import * as React from "react";
import { useEffect } from "react";
import { PageProps } from "gatsby";
import * as styles from "./styles.module.scss";
import { StaticImage } from "gatsby-plugin-image";
import classNames from "classnames";
import { Breadcrumb } from "../../../components/breadcrumb/breadcrumb";
import Layout from "../../../components/layout/layout";
import Container from "../../../components/container/container";
import Heading from "../../../components/basic-components/heading/heading";
import { ProductBar } from "../../../components/product-bar/product-bar";
import { Level } from "react-accessible-headings";
import scrollTo from "gatsby-plugin-smoothscroll";
import { Helmet } from "react-helmet";
import blogImage from "../../../images/cozum/kronik-oksuruk-nedir-nasil-gecer/Content-image.webp";
import { AnchorLink } from "gatsby-plugin-anchor-links";

const CoughPage: React.FC<PageProps> = ({ location }: PageProps) => {
  useEffect(() => {
    if (location.hash) {
      scrollTo(location.hash);
    }
  }, []);
  return (
    <Layout
      stickyHeader={false}
      seoConfig={{
        title: "Kronik Öksürük Nedir, Nasıl Geçer?",
        description:
          "ronik Öksürük Nedir, Nasıl Geçer? gibi soruların yanıtını içeriğimizi okuyarak öğrenmek için sayfamızı ziyaret edin!",
      }}
    >
      <Helmet>
        <script type="application/ld+json">
          {`
            {
                "@context": "https://schema.org",
                "@type": "BreadcrumbList",
                "itemListElement": [
                  {"@type": "ListItem","position": 1,"name": "Ana Sayfa","item": "https://www.bisolnatur.com.tr/"},
                  {"@type": "ListItem","position": 2,"name": "Öksürük","item": "https://www.bisolnatur.com.tr/oksuruk/"},
                  {"@type": "ListItem","position": 3,"name": "Kronik Öksürük Nedir, Nasıl Geçer?","item": "${location.href}"}
                ]
            }
          `}
        </script>
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "BlogPosting",
              "mainEntityOfPage": {
                "@type": "WebPage",
                "@id": "${location.href}"
              },
              "headline": "Kronik Öksürük Nedir, Nasıl Geçer?",
              "image": "${location.origin + blogImage}",  
              "author": {
                "@type": "Organization",
                "name": "Bisolnatur",
                "url": "${location.origin}"
              },  
              "publisher": {
                "@type": "Organization",
                "name": "Bisolnatur",
                "logo": {
                  "@type": "ImageObject",
                  "url": "${location.origin}/logo.svg"
                }
              },
              "datePublished": "2022-01-27",
              "dateModified": "2022-01-27"
            }
          `}
        </script>
        <script type="application/ld+json">
          {`
            {"@context":"https://schema.org","@type":"FAQPage","mainEntity":[{"@type":"Question","name":"Kronik Öksürük Nedir?","acceptedAnswer":{"@type":"Answer","text":"Yetişkinlerde sekiz hafta, çocuklarda ise dört haftadan uzun süren öksürüğe kronik öksürük denir. İşte bu durumda öksürüğün nedenini öğrenmek için doktora başvurmak zorundasınız. Kronik öksürük bir hastalık değil, bir sağlık sorununun habercisi olan semptomdur. Kronik öksürük çok rahatsız edici olabilir. Kişinin uyku kalitesi bozulur, baş dönmesi ve öksürüğün şiddetine bağlı olarak kaburga kırıkları bile görülebilir. Çocuklardaki kronik öksürük aileler için ciddi endişe kaynağıdır."}},{"@type":"Question","name":"Kronik Öksürük Nedenleri Nelerdir?","acceptedAnswer":{"@type":"Answer","text":"Kronik öksürük nedenlerinin başında sigara kullanmak veya sigara dumanına maruz kalmak gelir. Genellikle üç haftalık sigara içiminden sonra tüketicilerde kronik öksürük başlar. Sigaranın öksürüğe neden olmasının nedeni kimyasal tahriştir. Ancak kronik sigara içicilerinde kronik bronşit, zatürre, amfizem, bronşektazi, KOAH ve hatta akciğer kanseri gelişebilir ve bu durumlar da kronik öksürüğe neden olurlar. Bu nedenle sigara içenlerde öksürük endişe kaynağıdır."}},{"@type":"Question","name":"Kronik Öksürük Tanı Yöntemleri Nelerdir?","acceptedAnswer":{"@type":"Answer","text":"Eğer bir hastanın sekiz haftadan uzun süren öksürüğü varsa bu durum hekim için de dikkat gerektiren bir durumdur. Bu şikayetle doktora gittiğinizde hekiminiz öncelikle sizden detaylı bir öykü alacaktır. Hastalık belirtilerinin ilk ne zaman ortaya çıktığı, ne kadar süredir öksürdüğünüz, öksürüğün kuru veya balgamlı olması, öksürüğe eşlik eden belirtiler, sigara kullanıp kullanmadığınız önemli bilgilerdir. Hekiminiz aynı zamanda tıbbi özgeçmişinizi de öğrenmek isteyecektir."}}]}
          `}
        </script>
      </Helmet>
      <Breadcrumb
        pathname={location.pathname}
        noBg={true}
        className={styles.breadcrumb}
      />
      <Container fullWidth>
        <div className={classNames(styles.header)}>
          <StaticImage
            className={styles.image}
            src="../../../images/cozum/kronik-oksuruk-nedir-nasil-gecer/Content-image.webp"
            alt="Öksüren erkek görseli"
            width={1680}
            height={450}
          />
          <Container>
            <Heading>
              Kronik Öksürük Nedir, Nasıl
              <br /> Geçer?
            </Heading>
            <ul>
              <li>
                <AnchorLink
                  className="blue"
                  to="#kronik-oksuruk-nedir"
                  title="Kronik Öksürük Nedir?"
                >
                  Kronik Öksürük Nedir?
                </AnchorLink>
              </li>
              <li>
                <AnchorLink
                  className="blue"
                  to="#kronik-oksuruk-nedenleri-nelerdir"
                  title="Kronik Öksürük Nedenleri Nelerdir?"
                >
                  Kronik Öksürük Nedenleri Nelerdir?
                </AnchorLink>
              </li>
              <li>
                <AnchorLink
                  className="blue"
                  to="#kronik-oksuruk-tani-yontemleri-nelerdir"
                  title="Kronik Öksürük Tanı Yöntemleri Nelerdir?"
                >
                  Kronik Öksürük Tanı Yöntemleri Nelerdir?
                </AnchorLink>
              </li>
              <li>
                <AnchorLink
                  className="blue"
                  to="#kronik-oksuruk-tedavi-yontemleri-nelerdir"
                  title="Kronik Öksürük Tedavi Yöntemleri Nelerdir?"
                >
                  Kronik Öksürük Tedavi Yöntemleri Nelerdir?
                </AnchorLink>
              </li>
              <li>
                <AnchorLink
                  className="blue"
                  to="#kronik-oksuruk-nasil-gecer"
                  title="Kronik Öksürük Nasıl Geçer?"
                >
                  Kronik Öksürük Nasıl Geçer?
                </AnchorLink>
              </li>
            </ul>
          </Container>
        </div>
      </Container>
      <Container>
        <div className={styles.holder}>
          <p id="kronik-oksuruk-nedir" className="extra-bold">
            Öksürük akciğerlerimizin kendini savunma mekanizmalarından biridir.
            Öksürdüğümüzde akciğerlerimizi rahatsız eden mukus ve yabancı
            maddeleri dışarıya atarız. Bu nedenle normal öksürük faydalıdır.
            Ancak akciğerler iltihaplandığında veya başka bir hastalık durumunda
            öksürük daha şiddetli ve uzun sürelidir. Bu hastalığa bağlı
            öksürüktür. Çoğu öksürük kısa sürelidir. Bir soğuk algınlığı veya
            gribe yakalandığımızda öksürük genellikle birkaç gün veya bir hafta
            sürer ve sonra kendiliğinden geçer. Nadiren ise öksürük haftalar
            hatta aylar boyu devam edebilir. Bu durum ciddi bir sorunun
            habercisi olabilir. Öksürük soluduğumuz havayı akciğerlerin
            derinlerine iten nefes alma ile başlar. Ardından nefes borusu
            üzerindeki kapak olan glottis kapanır. Üçüncü adımda göğüs kafesi,
            diyafram ve karın kasları kuvvetli bir şekilde kasılır. Bu kaslar
            kasıldığında akciğerlerdeki havayı ağıza doğru hızlıca iter ve çok
            şiddetli bir ses ortaya çıkar. Öksürük bilinçli olarak da
            yapılabilir veya bir tetikleyici sonrası ortaya çıkabilir.
          </p>
          <section className={styles.kidsHolder}>
            <Level>
              <h2 className="orange underline">Kronik Öksürük Nedir?</h2>
              <p>
                Yetişkinlerde sekiz hafta, çocuklarda ise dört haftadan uzun
                süren öksürüğe kronik öksürük denir. İşte bu durumda öksürüğün
                nedenini öğrenmek için doktora başvurmak zorundasınız. Kronik
                öksürük bir hastalık değil, bir sağlık sorununun habercisi olan
                semptomdur. Kronik öksürük çok rahatsız edici olabilir. Kişinin
                uyku kalitesi bozulur, baş dönmesi ve öksürüğün şiddetine bağlı
                olarak kaburga kırıkları bile görülebilir. Çocuklardaki kronik
                öksürük aileler için ciddi endişe kaynağıdır.
              </p>
              <p>Kronik öksürüğe eşlik eden belirtiler genellikle şunlardır:</p>
              <ul>
                <li className="blue">Burun tıkanıklığı</li>
                <li className="blue">Geniz akıntısı</li>
                <li className="blue">Balgam çıkarma</li>
                <li className="blue">Hırıltılı solunum</li>
                <li className="blue">Sık geniz temizleme ihtiyacı</li>
                <li className="blue">Nefes darlığı</li>
                <li className="blue">Ses kısıklığı</li>
                <li className="blue">
                  Mide ekşimesi ve ağızda asit tadısi Boğaz ağrısı
                </li>
                <li className="blue">Nadiren öksürükle beraber kan tükürme</li>
                <li className="blue">Göğüste yanma hissi</li>
                <li className="blue">Boğaz ağrısı</li>
              </ul>
              <p>Kronik öksürük sıklıkla şu problemlere neden olabilir:</p>
              <ul>
                <li className="blue">
                  Şiddetli öksürük sırasında bayılma veya baş dönmesi
                </li>
                <li className="blue">Göğüste yanma veya göğüs ağrısı</li>
                <li className="blue">Baş ağrısı</li>
                <li className="blue">Yoğun endişe duymak</li>
                <li className="blue">
                  Sık sık uyku bölünmesi ve buna bağlı yorgunluk
                </li>
                <li className="blue">Öksürükle beraber idrar kaçırmak</li>
                <li className="blue">Öksürükle beraber gece terlemeleri</li>
                <li className="blue">Nadiren öksürükle beraber kan tükürmek</li>
                <li className="blue">Eşlik eden yüksek ateş</li>
                <li className="blue">Sebepsiz yere kilo kaybetmek</li>
              </ul>
              <StaticImage
                className={styles.image}
                src="../../../images/cozum/kronik-oksuruk-nedir-nasil-gecer/img.webp"
                alt="Öksüren kadın görseli"
                width={792}
                height={526}
              />
              <Level>
                <Heading id="kronik-oksuruk-nedenleri-nelerdir">
                  Kronik Öksürük Nedenleri Nelerdir?
                </Heading>
              </Level>
              <p>
                Kronik öksürük nedenlerinin başında sigara kullanmak veya sigara
                dumanına maruz kalmak gelir. Genellikle üç haftalık sigara
                içiminden sonra tüketicilerde kronik öksürük başlar. Sigaranın
                öksürüğe neden olmasının nedeni kimyasal tahriştir. Ancak kronik
                sigara içicilerinde kronik bronşit, zatürre, amfizem,
                bronşektazi, KOAH ve hatta akciğer kanseri gelişebilir ve bu
                durumlar da kronik öksürüğe neden olurlar. Bu nedenle sigara
                içenlerde öksürük endişe kaynağıdır.
              </p>
              <p>Sigara dışında kronik öksürüğün diğer nedenleri:</p>
              <ul>
                <li className="blue">
                  <b>Postnazal akıntı sendromu:</b> Burun solunum yollarının
                  parçası olan hassas bir organdır. Burnu tahriş eden toz,
                  alerjenler, virüsler sürekli mukus üretimine neden olurlar.
                  Bazen burun tıkanır ve akıntı dışarıya değil boğazın gerisine
                  doğru akar. Bu hastalarda sürekli devam eden postnazal akıntı
                  vardır. Üst solunum yollarında üretilen mukus sürekli olarak
                  boğaz bölgesine damlar ve tahriş oluşturur. Bu hastalarda
                  sürekli geniz temizleme ihtiyacı ve boğazda gıdıklanma
                  olabilir. Boğazdaki tahrişin sonucu öksürük olarak ortaya
                  çıkar.
                </li>
                <li className="blue">
                  <b>Astım:</b> Astımlı hastalarda havadaki toz ve tahriş edici
                  maddeler, soğuk hava, egzersiz ve alerjenlere bağlı olarak
                  kronik öksürük ortaya çıkabilir. Özellikle gece öksürüğü çok
                  rahatsız edicidir. Astımdaki öksürüğün nedeni hava yollarının
                  daralması anlamına gelen bronkospazmdır.
                </li>
                <li className="blue">
                  <b>Kronik Bronşit:</b> Akciğerin uzun süreli iltihaplı
                  halidir. Kronik öksürüğe neden olur. Özellikle sigara
                  içenlerde görülen KOAH hastalığının bir parçası olabilir.
                </li>
                <li className="blue">
                  <b>Gastroözefagial reflü hastalığı:</b> Bu hastalarda midedeki
                  asit yemek borusunu geçerek boğaza ulaşır ve kronik tahriş
                  yaratır. Bu hastalarda midede yanma, göğüs yanması, ağıza acı
                  su gelmesi ve mide ekşimesi gibi belirtiler de olabilir. Sonuç
                  uzun süren öksürüktür.
                </li>
                <li className="blue">
                  <b>Zatürre:</b> Akciğerlerin bakteriler tarafından oluşturulan
                  iltihaplı hastalığıdır. Bazen zatürre tedavi edilse bile
                  öksürük normalden uzun sürebilir.
                </li>
                <li className="blue">
                  <b>Tansiyon ilaçları:</b> Bazı tansiyon ilaçları yan etki
                  olarak öksürüğe neden olabilirler.
                </li>
                <li className="blue">
                  <b>Alerjiler:</b> Saman nezlesi gibi alerjik durumlarda kronik
                  öksürük görülebilir.
                </li>
                <li className="blue">
                  <b>Aspirasyon:</b> Yeme-içme sırasında yiyecek ve içeceklerin
                  boğaza ve akciğerlere kaçmasıdır. Uzun süreli geçmeyen
                  öksürükte bu ihtimalde akla gelmelidir. Akciğerlere kaçan çok
                  küçük bir yiyecek parçası bile akciğerde ciddi iltihaplanmaya
                  neden olabilir. Özellikle küçük çocuklarda ve yatalak
                  hastalarda sık rastlanır.
                </li>
                <li className="blue">
                  <b>Bronşektazi:</b> Bu hastalarda aşırı mukus üretimi
                  nedeniyle bazı bronşlarda aşırı genişleme mevcuttur. Bu
                  bölgelerde gaz alışverişi bozulabilir. Hastalarda kronik
                  öksürük vardır.
                </li>
                <li className="blue">
                  <b>Bronşiolit:</b> Özellikle küçük bebeklerde sıklıkla görülen
                  bu hastalıkta küçük hava yolları iltihaplanmış ve daralmıştır.
                  Çocuk daralmış hava yollarından havayı dışarıya atmak için
                  öksürme ihtiyacı duyar. Beraberinde solunum yolu hırıltısı
                  duyulabilir. Neden genellikle virüslerdir.
                </li>
                <li className="blue">
                  <b>Kistik Fibroz:</b> Bu genetik hastalıkta akciğerlerde aşırı
                  mukus üretimi ile giden kronik hastalık mevcuttur. Aynı
                  zamanda sindirim sistemi de tutulur ve kronik ishaller
                  görülebilir. Genellikle çocukluk çağında belirti veren bir
                  hastalıktır.
                </li>
                <li className="blue">
                  <b>Kalp hastalıklarına bağlı öksürük:</b> Bazı kalp
                  hastalarında kalp kasının kanı pompalama yeteneğinin
                  bozulmasına bağlı olarak öksürük görülebilir. Bu hastalar
                  yatakta düz yatamazlar, başlarını yükselterek yattıkları zaman
                  rahat ederler.
                </li>
                <li className="blue">
                  <b>Akciğer kanseri:</b> Kronik öksürük vakalarında belkide en
                  korkulan ihtimaldir. Özellikle kronik sigara içicileri akciğer
                  kanseri açısından risk altındadır. Akciğer kanserinde
                  öksürükle beraber kan tükürme ve göğüs ağrısı olabilir.
                </li>
                <li className="blue">
                  <b>Sarkoidoz:</b> Bu hastalıkta akciğerlerde, lenf bezlerinde,
                  deride ve gözlerde küçük oluşumlar vardır. Sarkoidoz akciğeri
                  yaygın tuttuğunda kronik öksürük görülür. Bu hastaların
                  akciğer grafilerinde hastalığa özel tipik görünüm vardır.
                </li>
                <li className="blue">
                  <b>İdiopatik pulmoner fibrozis:</b> Bu hastalarda bilinmeyen
                  bir nedenden ötürü akciğerlerde yaygın nedbe oluşur.
                </li>
                <li className="blue">
                  <b>Psikojenik öksürük:</b> Bazen araştırmalar sonucu hiçbir
                  nedene bağlı olmayan öksürükler psikojenik öksürük olarak
                  değerlendirilir.
                </li>
              </ul>
            </Level>
          </section>
          <section
            id="kronik-oksuruk-tani-yontemleri-nelerdir"
            className={styles.kidsHolder}
          >
            <Level>
              <Level>
                <Heading>Kronik Öksürük Tanı Yöntemleri Nelerdir?</Heading>
              </Level>
              <p>
                Eğer bir hastanın sekiz haftadan uzun süren öksürüğü varsa bu
                durum hekim için de dikkat gerektiren bir durumdur. Bu şikayetle
                doktora gittiğinizde hekiminiz öncelikle sizden detaylı bir öykü
                alacaktır. Hastalık belirtilerinin ilk ne zaman ortaya çıktığı,
                ne kadar süredir öksürdüğünüz, öksürüğün kuru veya balgamlı
                olması, öksürüğe eşlik eden belirtiler, sigara kullanıp
                kullanmadığınız önemli bilgilerdir. Hekiminiz aynı zamanda tıbbi
                özgeçmişinizi de öğrenmek isteyecektir.
              </p>
              <p>
                Hekiminiz daha sonra akciğerlerinizi steteskop ile
                dinleyecektir. Fizik muayenenin ardından tanı için bazı testler
                istemek gerekebilir. Bunlar:
              </p>
              <ul>
                <li className="blue">
                  Röntgen: Bir akciğer röntgeni zatürre, bronşit ve diğer
                  akciğer hastalıkları hakkında bilgi verebilir. Postnazal
                  akıntı varsa sinüzit, burunda polip gibi durumlar için sinüs
                  grafisi istenebilir.
                </li>
                <li className="blue">
                  Bilgisayarlı tomografi: Akciğer grafisinde görülmeyen
                  patolojileri değerlendirmek için bilgisayarlı tomografi çok
                  faydalıdır. Pek çok ciddi durum tomografide fark edilebilir.
                </li>
                <li className="blue">
                  Akciğer fonksiyon testleri: Solunum fonksiyon testleri akciğer
                  kapasitesini değerlendirmek için kullanılır. Astım ve KOAH
                  vakalarında çok kullanışlıdır.
                </li>
                <li className="blue">
                  Bronkoskopi: Özellikle yabancı cisim aspirasyonu düşünülen
                  durumlarda sıklıkla başvurulan bir yöntemdir. Ucunda ışık ve
                  kamera bulunan uzun ve esnek bir tüp bronşlara gönderilerek
                  sorunlu bölge tespit edilmeye çalışılır. Bu sırada gerekirse
                  biyopsi alınabilir.
                </li>
                <li className="blue">
                  Rinoskopi: Doktorunuz gerek gördüğünde fiber optik bir alet
                  yardımı ile burun ve sinüslerinizi inceleyebilir.
                </li>
                <li className="blue">
                  Asit reflü testleri: Mide reflüsü düşünülen hastalarda durumun
                  ciddiyetini tanımlamak için kullanılabilir.
                </li>
                <li className="blue">
                  Endoskopi: Yine reflü düşünülen durumlarda gerekli olabilir.
                </li>
                <li className="blue">
                  Balgam kültürü: Eğer akciğerde bir enfeksiyon düşünülüyorsa
                  etken mikrobu tanımlamak için balgam kültürü alınabilir.
                </li>
              </ul>
            </Level>
          </section>
          <section
            id="kronik-oksuruk-tedavi-yontemleri-nelerdir"
            className={styles.kidsHolder}
          >
            <Level>
              <Level>
                <Heading>Kronik Öksürük Tedavi Yöntemleri Nelerdir?</Heading>
              </Level>
              <p>
                Eğer kronik öksürükle beraber şu belirtiler varsa mutlaka
                önemsemeli ve doktora gitmelisiniz:
              </p>
              <ul>
                <li className="blue">38 derecenin üzerinde ateş</li>
                <li className="blue">Kan tükürme</li>
                <li className="blue">Göğüs ağrısı</li>
                <li className="blue">Nefes darlığı</li>
                <li className="blue">Yatakta düz uyuyamamak</li>
                <li className="blue">Çok fazla balgam çıkarmak</li>
                <li className="blue">Aşırı yorgunluk</li>
                <li className="blue">İştah kaybı ve kilo kaybı</li>
                <li className="blue">Gece terlemeleri</li>
                <li className="blue">8 haftadan uzun süren öksürük</li>
              </ul>
              <p>
                Kronik öksürüğü tedavi edebilmek için altta yatan nedeni bulmak
                gerekir. Bazen bir hastada birden fazla durum aynı anda
                bulunabilir. Eğer sigara kullanıyorsanız hekiminiz sigarayı
                bırakmanızı isteyebilir. Bu amaçla yardım almanız gerekebilir.
              </p>
              <p>
                Yüksek tansiyon nedeniyle kullandığınız ilaç öksürüğe neden
                oluyorsa hekiminiz ilacınızı değiştirebilir. Sorun kalp
                hastalığından kaynaklanıyorsa kalp kasını güçlendirici ilaçlar
                verilir. Tanı astım ise bronkodilatatör ve kortikosteroidler
                kullanılır. Bu ilaçlar daralmış olan hava yollarını gevşeterek
                daha rahat nefes almanızı sağlar. Astım tanısı eski olan bir
                hastada ilaçların dozunu artırmak gerekebilir. Astım
                hastalarında atakların sıklığını ve şiddetini azaltmak
                önemlidir. Sinüzitli hastalar dekonjestan ilaçlar ve burun açıcı
                toniklerle tedavi edilebilir. Bazen sinüzitli bir hasta için
                antibiyotik gerekebilir. Saman nezlesi hastalarında burundan
                kullanılan kortizonlu ilaçlar ve anti alerjik tedaviler vardır.
                Kronik bronşit ve zatürre gibi durumlarda antibiyotiklere
                ihtiyaç olabilir. Eğer neden reflü ise mide asidini azaltan ve
                reflüyü önleyen ilaçlar kullanılabilir. KOAH tedavisinde bronş
                genişletici ilaçlar ve steroidler kullanılır. Akciğerlere
                yabancı cisim kaçmışsa bu cisim bronkoskopi yoluyla çıkarılır ve
                uygun antibiyotikler verilir. Tanı akciğer kanseri ise tedavide
                cerrahi, radyoterapi ve kemoterapi birlikte kullanılır.
                Tedavinin şeklini hastalığın evresi belirler.
              </p>
              <p>
                Kronik öksürükte hekiminiz öksürüğe neden olan durumu tedavi
                ederken sizin konforunuzu sağlamak için öksürük şurubu da
                verebilir.
              </p>
              <p>
                <br />
              </p>
              <StaticImage
                className={styles.image}
                src="../../../images/cozum/kronik-oksuruk-nedir-nasil-gecer/img1.webp"
                alt="Muayene olan beyaz saçlı kadın görseli"
                width={792}
                height={526}
              />
            </Level>
          </section>
          <section
            id="kronik-oksuruk-nasil-gecer"
            className={styles.kidsHolder}
          >
            <Level>
              <Level>
                <Heading>Kronik Öksürük Nasıl Geçer?</Heading>
              </Level>
              <p>
                Eğer sekiz haftadan uzun süren öksürüğünüz varsa mutlaka doktora
                gidin. Hekiminizin verdiği tedavi ile birlikte evde kendi
                kendinize uygulayabileceğiniz şeyler de vardır. Bunlar:
              </p>
              <ul>
                <li className="blue">
                  Bol ılık su için. Günde en az 8 bardak su tüketin. Çay ve
                  kahve su yerine geçmez unutmayın. Bol su balgamı daha gevşek
                  hale getirir ve rahatça atılmasını sağlar.
                </li>
                <li className="blue">
                  Bitki çayları, et ve tavuk suyuna çorbalar boğazınızdaki
                  tahrişi giderir.
                </li>
                <li className="blue">
                  Sigarayı ve sigara dumanı solumayı bırakın.
                </li>
                <li className="blue">
                  Evinizdeki tozu, aşırı nemi ve küfü uzaklaştırın.
                </li>
                <li className="blue">
                  Burnunuzu açık tutmak için deniz suyu kullanın
                </li>
                <li className="blue">
                  Asit reflünüz varsa yatmadan 2-3 saat önce yemek yemeyi
                  bırakın. Aşırı yağlı yiyecekler tüketmeyin.
                </li>
                <li className="blue">
                  Evinizdeki havayı nemlendirin. Kuru öksürüğünüz varsa bir
                  buhar aleti kullanabilirsiniz ancak cihaz filtresinin
                  temizliğine dikkat edin.
                </li>
                <li className="blue">
                  Bol meyve yiyin. Meyvelerde bulunan liflerin öksürüğü azaltıcı
                  etkisi vardır.
                </li>
                <li className="blue">Bol bal tüketin. Ballı çaylar için.</li>
                <li className="blue">
                  Boğazınızdaki tahrişi gidermek için tuzlu su ile gargara
                  yapın.
                </li>
                <li className="blue">
                  Toz, duman ve hava kirleticilerden uzak durun.
                </li>
                <li className="blue">
                  Boğazınızı yumuşatmak için boğaz pastili kullanabilirsiniz.
                </li>
                <li className="blue">
                  Daha rahat nefes almanızı sağlıyorsa yastığınızı yükseltin.
                </li>
              </ul>
            </Level>
          </section>
          <section className={styles.kaynakca}>
            <Level>
              <Level>
                <Heading id="kaynakca">Kaynakça</Heading>
              </Level>
            </Level>
            <p>
              1- https://www.mayoclinic.org/diseases-conditions/chronic-cough/symptoms-causes/syc-20351575
            </p>
            <p>
              2- https://www.healthline.com/health/chronic-cough#symptoms
            </p>
            <p>
              3- https://my.clevelandclinic.org/health/diseases/15048-chronic-cough-overview
            </p>
            <p>
              4- https://www.health.harvard.edu/staying-healthy/that-nagging-cough
            </p>
            <p>
              5- https://www.ncbi.nlm.nih.gov/books/NBK430791/
            </p>
          </section>
        </div>
        <div className="date">
          <p>Yayınlama Tarihi: 2022-01-27</p>
          <p>Son güncellenme Tarihi: 2022-01-27</p>
        </div>
      </Container>
      <ProductBar />
    </Layout>
  );
};
export default CoughPage;
